import './bootstrap';
import '../css/app.css';
import {NotificationsProvider} from '@/Components/Providers/NotificationsProvider.jsx';
import MainLayout from '@/Layouts/MainLayout.jsx';
import {default as couleur_villas_theme} from '@/Themes/couleur-villas/theme.js';
import {default as eureka_theme} from '@/Themes/eureka/theme.js';
import {default as ya_k_theme} from '@/Themes/ya-k/theme.js';
import {createInertiaApp} from '@inertiajs/react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createRoot} from 'react-dom/client';

const appName = import.meta.env.VITE_APP_NAME || 'Eureka V2';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
        page.default.layout ??= (page) => <MainLayout page_name={name}>{page}</MainLayout>;
        return page;
    },
    setup({ el, App, props }) {
        const root = createRoot(el);
        const { url_brand } = props.initialPage.props;

        let theme = eureka_theme;
        switch (url_brand) {
            case 'ya-k':
                theme = ya_k_theme;
                break;
            case 'couleur-villas':
                theme = couleur_villas_theme;
                break;
        }

        root.render(
            <>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <NotificationsProvider>
                        <App {...props} />
                    </NotificationsProvider>
                </ThemeProvider>
            </>,
        );
    },
    progress: {
        color: '#4B5563',
    },
});
