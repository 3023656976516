import utils from '@/Themes/utils/utils.js';
import breakpoints from '@/Themes/ya-k/breakpoints.js';
import components from '@/Themes/ya-k/components.js';
import palette from '@/Themes/ya-k/palette.js';
import typography from '@/Themes/ya-k/typography.js';
import {createTheme} from '@mui/material';
import {frFR} from '@mui/material/locale';

let theme = createTheme(
    {
        ...utils,
        breakpoints: { ...breakpoints },
        palette: { ...palette },
        typography: { ...typography },
        components: { ...components },
    },
    frFR,
);

export default theme;
