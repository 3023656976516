const components_utils = {
    MuiButton: {
        styleOverrides: {
            root: {
                lineHeight: 1.5,
                textTransform: 'none',
            },
            sizeLarge: {
                padding: '8px 16px',
            },
            contained: {
                color: 'white',
            },
        },
    },
};

export default components_utils;
