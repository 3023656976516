import components_utils from '@/Themes/utils/components_utils.js';

const components = {
    MuiButton: {
        ...components_utils.MuiButton,
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                '&[readonly]': {
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    'input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        // Target TextField in readOnly mode
                        border: 'none',
                        '&:before': {
                            borderBottom: 'none', // Removes bottom border
                        },
                        '&:hover:not(.Mui-disabled):before': {
                            borderBottom: 'none', // Ensures no border on hover
                        },
                    },
                },
            },
        },
    },
};

export default components;
