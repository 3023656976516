import ApplicationLogo from '@/Components/ApplicationLogo.jsx';
import InertiaButton from '@/Components/Common/InertiaButton.jsx';
import {Link as InertiaLink} from '@inertiajs/react';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Container, IconButton, Menu, MenuItem, styled, Toolbar, Typography} from '@mui/material';
import {memo, useMemo, useState} from 'react';

const HeaderLogo = styled(ApplicationLogo)({
    objectFit: 'contain',
});

const getNavItems = (user) => {
    let result = [
        {
            name: 'Accueil',
            color: 'text',
            href: route('configurator.index'),
        },
    ];
    if (user) {
        result.push({
            name: 'Tableau de bord',
            color: 'text',
            href: route('dashboard.index'),
        });
        result.push({
            name: 'Votre compte',
            color: 'text',
            href: route('profile.edit'),
        });
        result.push({
            name: 'Déconnexion',
            variant: 'contained',
            method: 'POST',
            href: route('logout'),
        });
    } else {
        result.push({
            name: 'Connexion',
            variant: 'contained',
            href: route('login'),
        });
    }
    return result;
};

const Header = ({ brand, user }) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const nav_items = useMemo(() => getNavItems(user), [user]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <AppBar position="fixed" color="white" elevation={0} sx={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: 'block', md: 'none' } }}
                            >
                                {nav_items.map(({ name, href }) => (
                                    <MenuItem key={'mobile-' + name} onClick={handleCloseNavMenu}>
                                        <Typography sx={{ textAlign: 'center' }}>{name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <InertiaLink href={route('configurator.index')}>
                            <HeaderLogo sx={logoLinkStyle} />
                        </InertiaLink>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                            {nav_items.map(({ name, variant, color, method, href }) => (
                                <InertiaButton
                                    key={name}
                                    method={method}
                                    href={href}
                                    style={{ textDecoration: 'none' }}
                                    variant={variant}
                                    color={color}
                                    sx={{
                                        my: 2,
                                        display: 'block',
                                    }}
                                >
                                    {name}
                                </InertiaButton>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

const boxStyle = (theme) => ({
    position: 'fixed',
    top: 0,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: '0 64px',
    width: '100%',
    height: '64px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
});
const logoLinkStyle = {
    display: 'inline-block',
    flexGrow: { xs: 1, md: 0 },
    width: '132px',
    height: '35px',
};
export default memo(Header);
